
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { NotaryAggrementStatus } from "@/store/modules/activity.store";

  @Component({
    components: {
      GeneralStatusLabel: () => import("@/components/general/status-label.vue"),
    },
  })
  export default class NotaryAggrementStatusColumn extends Vue {
    @Prop({ default: NotaryAggrementStatus.blank }) status!: NotaryAggrementStatus;

    styles: { [key in NotaryAggrementStatus]: { borderColor: string; backgroundColor: string; color: string } } = {
      BLANK: { borderColor: "#F1F2F7", backgroundColor: "#F1F2F7", color: "#333" }, // gray
      WAITING: { borderColor: "#FF6A5B", backgroundColor: "#FF6A5B", color: "#fff" }, // red
      REQUESTED: { borderColor: "#FFCA54", backgroundColor: "#FFCA54", color: "#fff" }, // yellow
      APPROVED: { borderColor: "#1BCFA9", backgroundColor: "#1BCFA9", color: "#fff" }, // green
    };
  }
